import logger from 'OK/logger';
import { firstByClass } from 'OK/utils/dom';
import { ajax, updateBlockModelCallback } from 'OK/utils/utils';

/**
 * @type {number}
 * @private
 */
var STATUS_CHECKING_TIMEOUT = 1000;

let element, submitToggle, closeListener, submitListener, timeoutId, onClose;

function activate(el) {
    element = el;
    submitToggle = firstByClass(element, 'wp-button');

    if (!submitToggle) {
        return;
    }

    closeListener = close;
    submitListener = deactivate;

    element.addEventListener('click', closeListener);
    submitToggle.addEventListener('click', submitListener);

    (function checkStatus() {
        this.updateState(Notification.permission === 'denied');
        this.timeoutId = setTimeout(checkStatus, STATUS_CHECKING_TIMEOUT);
    })();
}

function submit(event) {
    event.stopPropagation();

    if (Notification.permission === 'denied') {
        return;
    }

    deactivate();
}

function close(event) {
    if (event.target !== element) {
        return false;
    }

    deactivate();
}

function deactivate() {
    if (!element) {
        return;
    }

    element.classList.add('invisible');

    if (submitToggle) {
        submitToggle.removeEventListener('click', submitListener);
        submitToggle = null;
    }

    if (element) {
        element.removeEventListener('click', closeListener);
        element = null;
    }

    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    logger.success('webpush.popup.close', Notification.permission === 'denied' ? 'denied' : 'allowed');

    if (typeof onClose === 'function') {
        onClose();
    }
}

function open(onCloseHandler) {
    logger.success('webpush.popup.open');
    onClose = onCloseHandler;

    return ajax({
        type: 'POST',
        url: '/dk?cmd=PopLayerOver&st.layer.cmd=NotificationPermissionBlock'
    }).then(updateBlockModelCallback);
}

function updateState(denied) {
    submitToggle.classList.toggle('__disabled', denied);
}

export default { activate, submit, close, deactivate, open, updateState };

export { activate, submit, close, deactivate, open, updateState };
